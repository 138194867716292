nav {
    background-color: transparent;
    position: absolute;
    top:0%;
    z-index: 20;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 20px ;
    margin-top: 40px;

    ul {
     list-style: none;
     display: flex;
     justify-content: space-between;

     @include max($desktop) {
        display: none;
      }
    }

    ul li a{
        // width: 100%;
        // display: inline-block;
        margin: 0px 20px;
        padding-bottom: 2px;
        font-family: $poppins;
        font-size: 24px;


        &:hover{
            border-bottom: 1px solid $green;
            cursor: pointer;
        }
        @include min($tablet) {
            font-size: 36px;
        }
        @include min($desktop) {
            font-size: 18px;
        }
        @include max($desktop) {
            @media screen and (orientation: landscape) {

                font-size: 1.6rem;

    
            }
          }

    }
    
}
.active {
    border-bottom: 1px solid $green;

}

.expandedMenu {
    background-color: black;
    position: fixed;
    height: 100vh;
    top: 0%;
    left: 0%;
    min-width:100vw;
    display: none;
    flex-direction: column;
    z-index: 30;
    padding-top: 30px;

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    ul li {
        padding: 10px 0px;
    }
}


.burgerMenu {
    margin-top: 5px;
    width: 20px;
    display: flex;
    flex-direction: column;

    @include min($desktop) {
        display: none;
      }
}
.closeMenu {
    margin-top: 10px;
    width: 20px;
}

.mobileMenuContainer { 
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;

    .burgerMenu{
        margin-top: 15px;
    }
}
.mobileMenuLogo { 
    display: flex;
    width: 90%;
    justify-content: space-between;

    .burgerMenu{
        margin-top: 15px;
    }
}
.mobileMenuLinks{
display: flex;
align-items: center;
height: 100%;
}

.logoButton {
    cursor: pointer;
}

.navbarButton{
    margin-top: -17px;
    padding-bottom: 0px;
    &:hover{
        border-bottom: 0px;
    }
  }

.navbarMobileButton {

    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
    font-size: 21px;
    width: 189px;
    height: 74px;
  
    border-radius: 60px;
    &:hover{
        border-bottom: 0px;
    }
    @include min($tablet) {
        font-size: 30px;
        width: 264px;
        height: 105px;
        border-radius: 60px;
    }
    @include min($desktop) {
        font-size: 18px;
        width: 166px;
        height: 66px;
        border-radius: 36px;
    }
    @include max($desktop) {
        @media screen and (orientation: landscape) {
            bottom:0px;
            position: relative;
            width: 138px;
            height: 56px;
            font-size: 1.6rem;

            border-radius: 28px;

        }
      }

}

.learnMoreButton{
    font-size: 16px;
    width: 148px;
    height: 64px;
    border-radius: 36px;
    @include min($desktop) {
        font-size: 18px;
        width: 159px;
        height: 66px;
        border-radius: 36px;
    }
}

.seeOurWorkButton{
    font-size: 16px;
    width: 159px;
    height: 64px;
    border-radius: 36px;
    @include min($desktop) {
        font-size: 18px;
        width: 172px;
        height: 66px;
        border-radius: 36px;
    }
}

