.caseHeader {
  height: 500px;
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  align-items: center;
  margin-bottom: 80px;

  nav {
    width: 90%;
  }

  .headerCaseBox {
    position: absolute;
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 50px;
  }
}

.casePartnership {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.casePartnership-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include min($tablet) {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }
}

@media (min-width: 1700px){
  .casePartnership-container {
    padding: 60px 0px;
    align-items: center;
    width: 90%;
   }

}

.partnershipLogoContainer {
  width: 25%;
  align-self: start;
  p {
    padding-bottom: 20px;
    border-bottom: 1px solid $green;
    margin-bottom: 30px;
    font-style: italic;
  }

  @include max($tablet) {
    width: 60%;
    align-self: start;
    margin-left: 30px;
    margin-bottom: 50px;
  }
}

.partnershipTextContainer {
  width: 90%;
  font-family: $poppins;
  font-size: 1.6rem;

  div {
    margin-bottom: 40px;
    .title {
      font-weight: 600;
    }
  }
  p {
    margin-bottom: 10px;
  }
  @include min($tablet) {
    width: 50%;
  }
}

.devices {
  display: flex;
  justify-content: center;
  margin-bottom: 140px;

  img {
    width: 90%;
  }
}

.previous-campaign {
  display: flex;
  margin: auto;
  margin-bottom: 50px;
  font-weight: 400;
  font-size: 18px;
  padding: 25px 0;
  border-bottom: solid 1px #34E0A1;
  width: 90%;
}

.no-border { border: none !important; font-style: normal !important; }

.iframe-container {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 50%;
  margin-bottom: 100px;
  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.caseStats {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  h1 {
    @include max($tablet) {
      margin-bottom: 10px;
    }
  }
  div {
    display: flex;
    width: 90%;
    justify-content: space-between;

    @include max($tablet) {
      flex-direction: column;
      margin-bottom: 15px;
    }
  }
}

.statsCard {
  display: flex;
  flex-direction: column;
  width: 30%;
  h2 {
    font-family: $poppins;
  }
  div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
  }
}

.workTitle {
  font-family: $poppins;
  font-weight: 600;
  font-size: 3rem;
}

.workSubtitle {
  font-family: $merriweather;
  font-weight: 400;
  font-size: 1.5rem;
}

.photoBox {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 140px;
  img {

    width: 90%;
  }
}

.bannerAward {
  position: absolute;
  bottom: 65%;
  right: 0%;

  @include min($tablet) {
    bottom: 10%;
  }
}

.bannerImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
  z-index: -1;
}

.showDesktop{
  display: none;
  @include min($desktop) {
    display: block;
  }
}
.showTablet{
  display: none;
  @include min($tablet) {
    display: block;
  }
  @include min($desktop) {
    display: none;
  }
}
.showMobile{
  display: none;
  @include max($tablet) {
    display: block;
  }
}