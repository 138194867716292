html {
  font-size: 10px;      // 1rem = 10px
}

body {
  background-color: $black;

  font-feature-settings: 'tnum', 'lnum';
  color: $white;

  -webkit-font-smoothing: antialiased;
}

.container {
  width: calc(100% - 50px);
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;

  @include min($tablet) {
    width: calc(100% - 160px);
  }
}
