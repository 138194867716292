// DASH

.dash {
  position: relative;

  padding-left: 85px;

  @include min($tablet) {
    width: 80%;
    margin-left: auto;
    padding-left: 75px;
  }

  @include min($desktop) {
    width: 65%;
    padding-left: 110px;
  }
}

  // before

  .dash::before {
    content: "";

    transform: scale3d(0, 1, 1);
    transform-origin: left center;
    transition-property: transform;
    transition-duration: .5s;
    transition-timing-function: $ease;

    position: absolute;
    top: 6px;
    left: 0;

    width: 70px;
    height: 10px;

    background-color: $green;

    @include min($tablet) {
      width: 60px;
    }

    @include min($desktop) {
      width: 92px;
      height: 12px;
    }
  }

  // inview

  .is-inview .dash::before {
    transform: scale3d(1, 1, 1);
  }
