// CONNECT

.connect {
  margin-bottom: 80px;

  @include min($tablet) {
    margin-bottom: 100px;
  }

  @include min($desktop) {
    margin-bottom: 285px;
  }
}

// CONTAINER

.connect-container {

}

// HEADING

.connect-heading {
  margin-bottom: 25px;

  @include min($desktop) {
    margin-bottom: 35px;
  }
}

// SUBHEAD

.connect-subhead {

}

  // before

  .connect-subhead::before {
    transition-delay: .25s;
  }
