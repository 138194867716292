:where(:not(svg):not(svg *)) {
  all: unset;
  display: revert;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

@font-face {
  font-family: Merriweather;
  font-weight: 400;
  src: url("merriweather-400.574fb52b.ttf") format("truetype");
}

@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("poppins-500.99ed75b1.ttf") format("truetype");
}

@font-face {
  font-family: Trip Sans;
  font-weight: 500;
  src: url("trip-sans-500.b190aa3b.ttf") format("truetype");
}

html {
  font-size: 10px;
}

body {
  font-feature-settings: "tnum", "lnum";
  color: #fff;
  -webkit-font-smoothing: antialiased;
  background-color: #000;
}

.container {
  width: calc(100% - 50px);
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 768px) {
  .container {
    width: calc(100% - 160px);
  }
}

.modal {
  z-index: 1;
}

.dash {
  padding-left: 85px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .dash {
    width: 80%;
    margin-left: auto;
    padding-left: 75px;
  }
}

@media screen and (min-width: 1024px) {
  .dash {
    width: 65%;
    padding-left: 110px;
  }
}

.dash:before {
  content: "";
  transform-origin: 0;
  width: 70px;
  height: 10px;
  background-color: #34e0a1;
  transition-property: transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.44, .21, 0, 1);
  position: absolute;
  top: 6px;
  left: 0;
  transform: scale3d(0, 1, 1);
}

@media screen and (min-width: 768px) {
  .dash:before {
    width: 60px;
  }
}

@media screen and (min-width: 1024px) {
  .dash:before {
    width: 92px;
    height: 12px;
  }
}

.is-inview .dash:before {
  transform: scale3d(1, 1, 1);
}

.enter {
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.44, .21, 0, 1);
}

.enter-up {
  transform: translate3d(0, 5vh, 0);
}

.enter.is-inview, .is-inview .enter {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.delay-1 {
  transition-delay: .25s;
}

.delay-2 {
  transition-delay: .5s;
}

.h1 {
  font-family: Poppins, sans-serif;
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 1.1;
}

@media screen and (min-width: 768px) {
  .h1 {
    font-size: 4.9rem;
  }
}

@media screen and (min-width: 1024px) {
  .h1 {
    font-size: 9.6rem;
  }
}

.h2 {
  font-family: Merriweather, serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .h2 {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 1024px) {
  .h2 {
    font-size: 1.8rem;
  }
}

.h3 {
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .h3 {
    font-size: 1.7rem;
  }
}

@media screen and (min-width: 1024px) {
  .h3 {
    font-size: 1.8rem;
  }
}

.h4 {
  font-family: Merriweather, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .h4 {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1024px) {
  .h4 {
    font-size: 1.5rem;
  }
}

.about {
  margin-bottom: 80px;
}

@media screen and (min-width: 1024px) {
  .about {
    margin-bottom: 245px;
  }
}

.about-rotate {
  height: 46px;
  margin-bottom: 40px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .about-rotate {
    height: 54px;
  }
}

@media screen and (min-width: 1024px) {
  .about-rotate {
    height: 106px;
    margin-bottom: 70px;
  }
}

.about-rotate-item {
  opacity: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity, transform;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.44, .21, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 15px, 0);
}

.about-rotate-item.is-active {
  opacity: 1;
  transition-delay: .25s;
  transform: translate3d(0, 0, 0);
}

.about-rotate-image {
  width: 226px;
  height: 42px;
  text-indent: 101%;
  white-space: nowrap;
  background-image: url("logo-small.613484eb.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .about-rotate-image {
    width: 326px;
    height: 61px;
    background-image: url("logo-medium.75741b53.svg");
  }
}

@media screen and (min-width: 1024px) {
  .about-rotate-image {
    width: 660px;
    height: 123px;
    background-image: url("logo-large.799d7fe2.svg");
  }
}

.about-subhead:before {
  transition-delay: .5s;
}

.callout {
  margin-bottom: 80px;
}

@media screen and (min-width: 1024px) {
  .callout {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 768px) {
  .callout-heading {
    max-width: 520px;
  }
}

@media screen and (min-width: 1024px) {
  .callout-heading {
    max-width: 1000px;
  }
}

.callout-letters {
  color: #34e0a1;
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .callout-letters {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .callout-letters {
    margin-bottom: 40px;
  }
}

.callout-letter {
  opacity: 0;
  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: cubic-bezier(.44, .21, 0, 1);
  display: inline-block;
}

.callout-container.is-inview .callout-letter {
  opacity: 1;
}

.callout-button {
  width: 154px;
  height: 64px;
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: flex;
}

@media screen and (min-width: 768px) {
  .callout-button {
    width: 166px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .callout-button {
    transition: opacity .25s cubic-bezier(.44, .21, 0, 1);
  }

  .callout-button:hover {
    opacity: .75;
  }
}

.company {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .company-container {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.company-heading {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .company-heading {
    max-width: 265px;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .company-heading {
    max-width: 510px;
  }
}

.company-logos {
  width: 155px;
  height: 155px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media screen and (min-width: 768px) {
  .company-logos {
    width: 192px;
    height: 192px;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .company-logos {
    width: 308px;
    height: 308px;
  }
}

@media screen and (min-width: 1280px) {
  .company-logos {
    margin-right: 100px;
  }
}

.company-logo, .header-slide {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: opacity .25s cubic-bezier(.44, .21, 0, 1), transform .25s cubic-bezier(.44, .21, 0, 1), visibility 0s linear .25s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 15px, 0);
}

.company-logo.is-active, .header-slide.is-active {
  opacity: 1;
  visibility: visible;
  transition-delay: .25s;
  transform: translate3d(0, 0, 0);
}

.company-logo-1 {
  background-image: url("1.dff573cb.jpeg");
}

.company-logo-2 {
  background-image: url("2.e6c9a186.jpeg");
}

.company-logo-3 {
  background-image: url("3.83382e5c.jpeg");
}

.company-logo-4 {
  background-image: url("4.5cb7ccda.jpeg");
}

.company-logo-5 {
  background-image: url("5.2fd84439.jpeg");
}

.company-logo-6 {
  background-image: url("6.7b85c22e.jpeg");
}

.company-logo-7 {
  background-image: url("7.ab613c13.jpeg");
}

.company-logo-8 {
  background-image: url("8.29607597.jpeg");
}

.company-logo-9 {
  background-image: url("9.e9e6080c.jpeg");
}

.company-logo-10 {
  background-image: url("10.45757cf8.jpeg");
}

.company-logo-11 {
  background-image: url("11.23580ee9.jpeg");
}

.company-logo-12 {
  background-image: url("12.c2d25858.jpeg");
}

.company-logo-13 {
  background-image: url("13.2d67e08d.jpeg");
}

.company-logo-14 {
  background-image: url("14.3abf899e.jpeg");
}

.company-logo-15 {
  background-image: url("15.a4a75069.jpeg");
}

.company-logo-16 {
  background-image: url("16.6e21f193.jpeg");
}

.company-logo-17 {
  background-image: url("17.cc6ff4cd.jpeg");
}

.company-logo-18 {
  background-image: url("18.607ea501.jpeg");
}

.company-logo-19 {
  background-image: url("19.fd917589.jpeg");
}

.company-logo-20 {
  background-image: url("20.315e5567.jpeg");
}

.company-logo-21 {
  background-image: url("21.9d3a228e.jpeg");
}

.company-logo-22 {
  background-image: url("22.4eda1b48.jpeg");
}

.company-logo-23 {
  background-image: url("23.b0f95288.jpeg");
}

.company-logo-24 {
  background-image: url("24.7730c332.jpeg");
}

.company-logo-25 {
  background-image: url("25.2816ad30.jpeg");
}

.company-logo-26 {
  background-image: url("26.0173e7bd.jpeg");
}

.company-logo-27 {
  background-image: url("27.44ac2914.jpeg");
}

.company-logo-28 {
  background-image: url("28.e8b09120.jpeg");
}

.company-logo-29 {
  background-image: url("29.43f5cd40.jpeg");
}

.header-slide-20 {
  background-image: url("20.315e5567.jpeg");
}

.header-slide-21 {
  background-image: url("21.9d3a228e.jpeg");
}

.header-slide-22 {
  background-image: url("22.4eda1b48.jpeg");
}

.header-slide-23 {
  background-image: url("23.b0f95288.jpeg");
}

.header-slide-24 {
  background-image: url("24.7730c332.jpeg");
}

.header-slide-25 {
  background-image: url("25.2816ad30.jpeg");
}

.header-slide-26 {
  background-image: url("26.0173e7bd.jpeg");
}

.header-slide-27 {
  background-image: url("27.44ac2914.jpeg");
}

.header-slide-28 {
  background-image: url("28.e8b09120.jpeg");
}

.header-slide-29 {
  background-image: url("29.43f5cd40.jpeg");
}

.connect {
  margin-bottom: 80px;
}

@media screen and (min-width: 768px) {
  .connect {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1024px) {
  .connect {
    margin-bottom: 285px;
  }
}

.connect-heading {
  margin-bottom: 25px;
}

@media screen and (min-width: 1024px) {
  .connect-heading {
    margin-bottom: 35px;
  }
}

.connect-subhead:before {
  transition-delay: .25s;
}

.footer-container {
  border-top: 2px solid #34e0a1;
  padding-top: 50px;
  padding-bottom: 45px;
}

@media screen and (min-width: 768px) {
  .footer-container {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .footer-container {
    align-items: center;
    display: flex;
  }
}

.footer-logo {
  width: 92px;
  height: 92px;
  text-indent: 101%;
  white-space: nowrap;
  background-image: url("logo-small.d80a52ad.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 20px;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .footer-logo {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1024px) {
  .footer-logo {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.footer-legal {
  max-width: 240px;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (min-width: 768px) {
  .footer-legal {
    max-width: 350px;
  }
}

@media screen and (min-width: 1024px) {
  .footer-legal {
    max-width: none;
  }
}

.footer-links {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .footer-links {
    flex-direction: row;
  }
}

.footer-link {
  cursor: pointer;
  margin-bottom: 5px;
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: underline;
}

@media screen and (min-width: 1024px) {
  .footer-link {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .footer-link {
    transition: opacity .25s cubic-bezier(.44, .21, 0, 1);
  }

  .footer-link:hover {
    opacity: .75;
  }
}

.footer-link:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  .footer-link:last-child {
    margin-right: 0;
  }
}

.header {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header {
    margin-bottom: 245px;
  }
}

@media screen and (min-width: 1024px) {
  .header {
    margin-bottom: 245px;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .header {
    height: 900px;
  }
}

.headerAdWeek {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  display: flex;
  position: relative;
}

.headerAdWeek span {
  color: #34e0a1;
}

@media screen and (min-width: 768px) {
  .headerAdWeek {
    margin-bottom: 245px;
  }
}

@media screen and (min-width: 1024px) {
  .headerAdWeek {
    margin-bottom: 245px;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .headerAdWeek {
    height: 900px;
  }
}

.header-video {
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity .5s linear;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.header-video.is-loaded {
  opacity: 1;
}

.logoWrapper {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .logoWrapper {
    height: 900px;
  }
}

.header-logo {
  width: 182px;
  height: 123px;
  text-indent: 101%;
  white-space: nowrap;
  background-image: url("logo.0a2cb320.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1024px) {
  .header-logo {
    width: 350px;
    height: 236px;
  }
}

.header-arrow {
  width: 34px;
  height: 34px;
  cursor: pointer;
  background-image: url("arrow-small.e8450d2c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: -17px;
  display: block;
  position: absolute;
  bottom: 20px;
  left: 50%;
}

@media screen and (min-width: 768px) {
  .header-arrow {
    width: 44px;
    height: 44px;
    background-image: url("arrow-large.e53ca256.svg");
    margin-left: -22px;
    bottom: 30px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-color: #000;
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.swiper-pagination {
  margin-bottom: 10px;
}

.swiper-pagination .swiper-pagination-bullet {
  height: 16px;
  width: 16px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #34e0a1;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #34e0a1;
}

.headerBox {
  max-width: 250px;
}

.headerBox a {
  margin-top: 30px;
}

.headerBox p {
  width: 80%;
  margin: 25px 0;
  font-family: Poppins, sans-serif;
}

@media screen and (min-width: 768px) {
  .headerBox {
    max-width: 320px;
  }
}

@media screen and (min-width: 1024px) {
  .headerBox {
    max-width: 1000px;
  }
}

.headerBoxAdweek {
  max-width: 250px;
  margin-top: 200px;
}

@media screen and (min-width: 768px) {
  .headerBoxAdweek h1 {
    width: 100%;
    font-size: 8.2rem;
  }
}

@media screen and (min-width: 910px) {
  .headerBoxAdweek h1 {
    width: 100%;
    font-size: 10rem;
  }
}

.headerBoxAdweek a {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .headerBoxAdweek {
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .headerBoxAdweek {
    max-width: 954px;
  }
}

.boxWrapper {
  width: 90%;
  height: 100%;
  z-index: 1;
  justify-content: left;
  align-items: center;
  margin: auto;
  display: flex;
  position: absolute;
  left: 5%;
}

.boxWrapperAdWeek {
  width: 90%;
  height: 100%;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  margin: auto;
  display: flex;
}

.boxWrapperAdWeek p {
  width: 310px;
  margin: 25px 0;
  font-family: Poppins, sans-serif;
}

@media screen and (min-width: 768px) {
  .boxWrapperAdWeek p {
    width: 480px;
  }
}

@media screen and (min-width: 1024px) {
  .boxWrapperAdWeek p {
    width: 100%;
  }
}

@media screen and (max-width: 389px) {
  .boxWrapperAdWeek p {
    width: 100%;
  }
}

.videoHeader {
  width: 90%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

@media screen and (max-width: 1023px) {
  .videoHeader {
    flex-direction: column;
  }
}

@media screen and (min-width: 1024px) {
  .videoHeader {
    height: 350px;
    justify-content: space-between;
  }
}

.videoHeaderText {
  align-items: left;
  width: 45%;
  flex-direction: column;
  display: flex;
}

.videoHeaderText h1 {
  width: 60%;
}

@media screen and (min-width: 768px) {
  .videoHeaderText h1 {
    width: 20%;
  }
}

@media screen and (min-width: 450px) {
  .videoHeaderText h1 {
    width: 35%;
  }
}

@media screen and (min-width: 1024px) {
  .videoHeaderText h1 {
    width: 70%;
  }
}

.videoHeaderText h2 {
  width: 80%;
  margin-top: 10px;
}

.videoHeaderText .h2Custom {
  margin: 40px 0 !important;
}

@media screen and (max-width: 1023px) {
  .videoHeaderText {
    width: 90%;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .videoHeaderText {
    margin-top: 130px;
  }
}

.videoHeaderVideo {
  width: 45%;
}

.videoHeaderVideo iframe {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .videoHeaderVideo {
    margin-top: 0;
  }
}

@media screen and (max-width: 1023px) {
  .videoHeaderVideo {
    width: 90%;
    margin-top: 20px;
  }
}

.italic {
  color: #fff;
  font-style: italic;
}

.colorGreen {
  color: #34e0a1;
}

.wandererText {
  margin: 40px 0;
}

@media screen and (max-width: 1023px) {
  .wandererText {
    margin: 25px 0;
  }
}

.wandererBanner {
  position: absolute;
}

.background-gradient {
  width: 100%;
  height: 100%;
  background: linear-gradient(#000 0%, rgba(0, 0, 0, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 1024px) {
  .background-gradient {
    background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
  }
}

.casesHeader {
  justify-content: center;
  margin-bottom: 40px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .casesHeader {
    height: 280px;
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .casesHeader {
    height: 400px;
    margin-bottom: 40px;
  }
}

.casesHeader span {
  color: #34e0a1;
}

.titleContainer {
  width: 90%;
  justify-content: left;
  display: flex;
}

.worksTitle {
  width: 90%;
  margin-top: 100px;
}

@media screen and (min-width: 550px) {
  .worksTitle {
    width: 60%;
  }
}

@media screen and (min-width: 768px) {
  .worksTitle {
    width: 50%;
    margin-top: 150px;
  }
}

@media screen and (min-width: 1024px) {
  .worksTitle {
    width: 80%;
    margin-top: 140px;
  }
}

@media screen and (min-width: 1400px) {
  .worksTitle {
    width: 50%;
  }
}

.gridFather {
  width: 100%;
}

.casesGrid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  margin: 0 20px 100px;
  display: grid;
}

@media screen and (min-width: 768px) {
  .casesGrid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0 40px 150px;
  }
}

@media screen and (min-width: 1024px) {
  .casesGrid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0 60px 200px;
  }
}

.casesCard {
  position: relative;
}

.casesCard:hover {
  cursor: pointer;
}

.awardCard {
  position: absolute;
  top: 5%;
  right: 0%;
}

.gridImg {
  width: 100%;
  object-fit: fill;
}

.gridText {
  padding-top: 18px;
}

.caseHeader {
  height: 500px;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  align-items: center;
  margin-bottom: 80px;
  display: flex;
  position: relative;
}

.caseHeader nav {
  width: 90%;
}

.caseHeader .headerCaseBox {
  width: 90%;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 50px;
  display: flex;
  position: absolute;
}

.casePartnership {
  justify-content: center;
  margin-bottom: 100px;
  display: flex;
}

.casePartnership-container {
  width: 100%;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .casePartnership-container {
    width: 90%;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media (min-width: 1700px) {
  .casePartnership-container {
    width: 90%;
    align-items: center;
    padding: 60px 0;
  }
}

.partnershipLogoContainer {
  width: 25%;
  align-self: start;
}

.partnershipLogoContainer p {
  border-bottom: 1px solid #34e0a1;
  margin-bottom: 30px;
  padding-bottom: 20px;
  font-style: italic;
}

@media screen and (max-width: 767px) {
  .partnershipLogoContainer {
    width: 60%;
    align-self: start;
    margin-bottom: 50px;
    margin-left: 30px;
  }
}

.partnershipTextContainer {
  width: 90%;
  font-family: Poppins, sans-serif;
  font-size: 1.6rem;
}

.partnershipTextContainer div {
  margin-bottom: 40px;
}

.partnershipTextContainer div .title {
  font-weight: 600;
}

.partnershipTextContainer p {
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .partnershipTextContainer {
    width: 50%;
  }
}

.devices {
  justify-content: center;
  margin-bottom: 140px;
  display: flex;
}

.devices img {
  width: 90%;
}

.previous-campaign {
  width: 90%;
  border-bottom: 1px solid #34e0a1;
  margin: auto auto 50px;
  padding: 25px 0;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.no-border {
  border: none !important;
  font-style: normal !important;
}

.iframe-container {
  width: 100%;
  justify-content: center;
  margin-bottom: 100px;
  padding-bottom: 50%;
  display: flex;
  position: relative;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.caseStats {
  justify-content: center;
  margin-bottom: 100px;
  display: flex;
}

@media screen and (max-width: 767px) {
  .caseStats h1 {
    margin-bottom: 10px;
  }
}

.caseStats div {
  width: 90%;
  justify-content: space-between;
  display: flex;
}

@media screen and (max-width: 767px) {
  .caseStats div {
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.statsCard {
  width: 30%;
  flex-direction: column;
  display: flex;
}

.statsCard h2 {
  font-family: Poppins, sans-serif;
}

.statsCard div {
  height: 100%;
  flex-direction: column;
  justify-content: start;
  display: flex;
}

.workTitle {
  font-family: Poppins, sans-serif;
  font-size: 3rem;
  font-weight: 600;
}

.workSubtitle {
  font-family: Merriweather, serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.photoBox {
  width: 100%;
  justify-content: center;
  margin-bottom: 140px;
  display: flex;
}

.photoBox img {
  width: 90%;
}

.bannerAward {
  position: absolute;
  bottom: 65%;
  right: 0%;
}

@media screen and (min-width: 768px) {
  .bannerAward {
    bottom: 10%;
  }
}

.bannerImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -1;
  display: block;
}

.showDesktop {
  display: none;
}

@media screen and (min-width: 1024px) {
  .showDesktop {
    display: block;
  }
}

.showTablet {
  display: none;
}

@media screen and (min-width: 768px) {
  .showTablet {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .showTablet {
    display: none;
  }
}

.showMobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .showMobile {
    display: block;
  }
}

nav {
  z-index: 20;
  width: 90%;
  background-color: rgba(0, 0, 0, 0);
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  position: absolute;
  top: 0%;
}

nav ul {
  justify-content: space-between;
  list-style: none;
  display: flex;
}

@media screen and (max-width: 1023px) {
  nav ul {
    display: none;
  }
}

nav ul li a {
  margin: 0 20px;
  padding-bottom: 2px;
  font-family: Poppins, sans-serif;
  font-size: 24px;
}

nav ul li a:hover {
  cursor: pointer;
  border-bottom: 1px solid #34e0a1;
}

@media screen and (min-width: 768px) {
  nav ul li a {
    font-size: 36px;
  }
}

@media screen and (min-width: 1024px) {
  nav ul li a {
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  nav ul li a {
    font-size: 1.6rem;
  }
}

.active {
  border-bottom: 1px solid #34e0a1;
}

.expandedMenu {
  height: 100vh;
  min-width: 100vw;
  z-index: 30;
  background-color: #000;
  flex-direction: column;
  padding-top: 30px;
  display: none;
  position: fixed;
  top: 0%;
  left: 0%;
}

.expandedMenu ul {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.expandedMenu ul li {
  padding: 10px 0;
}

.burgerMenu {
  width: 20px;
  flex-direction: column;
  margin-top: 5px;
  display: flex;
}

@media screen and (min-width: 1024px) {
  .burgerMenu {
    display: none;
  }
}

.closeMenu {
  width: 20px;
  margin-top: 10px;
}

.mobileMenuContainer {
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.mobileMenuContainer .burgerMenu {
  margin-top: 15px;
}

.mobileMenuLogo {
  width: 90%;
  justify-content: space-between;
  display: flex;
}

.mobileMenuLogo .burgerMenu {
  margin-top: 15px;
}

.mobileMenuLinks {
  height: 100%;
  align-items: center;
  display: flex;
}

.logoButton {
  cursor: pointer;
}

.navbarButton {
  margin-top: -17px;
  padding-bottom: 0;
}

.navbarButton:hover {
  border-bottom: 0;
}

.navbarMobileButton {
  width: 189px;
  height: 74px;
  border-radius: 60px;
  margin: auto;
  font-size: 21px;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

.navbarMobileButton:hover {
  border-bottom: 0;
}

@media screen and (min-width: 768px) {
  .navbarMobileButton {
    width: 264px;
    height: 105px;
    border-radius: 60px;
    font-size: 30px;
  }
}

@media screen and (min-width: 1024px) {
  .navbarMobileButton {
    width: 166px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;
  }
}

@media screen and (max-width: 1023px) and (orientation: landscape) {
  .navbarMobileButton {
    width: 138px;
    height: 56px;
    border-radius: 28px;
    font-size: 1.6rem;
    position: relative;
    bottom: 0;
  }
}

.learnMoreButton {
  width: 148px;
  height: 64px;
  border-radius: 36px;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .learnMoreButton {
    width: 159px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;
  }
}

.seeOurWorkButton {
  width: 159px;
  height: 64px;
  border-radius: 36px;
  font-size: 16px;
}

@media screen and (min-width: 1024px) {
  .seeOurWorkButton {
    width: 172px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;
  }
}

.visitAdweek {
  justify-content: center;
  display: flex;
}

.visitText {
  width: 100%;
}

@media screen and (min-width: 1024px) {
  .visitText {
    width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .visitTitlesBox {
    width: 50%;
  }
}

.visitTitlesBox .h1 {
  font-size: 4.2rem;
}

@media screen and (min-width: 768px) {
  .visitTitlesBox .h1 {
    font-size: 4.7rem;
  }
}

@media screen and (min-width: 1024px) {
  .visitTitlesBox .h1 {
    font-size: 6.2rem;
  }
}

@media screen and (min-width: 1200px) {
  .visitTitlesBox .h1 {
    font-size: 7.5rem;
  }
}

@media screen and (min-width: 1470px) {
  .visitTitlesBox .h1 {
    font-size: 9.2rem;
  }
}

.visitTitlesBox .h2 {
  width: 100%;
  font-size: 2rem;
}

@media screen and (min-width: 768px) {
  .visitTitlesBox .h2 {
    width: 150%;
  }
}

@media screen and (max-width: 767px) {
  .visitTitlesBox .h2 {
    margin: 10px 0;
    font-size: 1.5rem;
  }
}

.visitTextBox {
  width: 100%;
  margin-top: 30px;
}

.visitTextBox p {
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .visitTextBox {
    width: 60%;
    margin-top: 0;
    margin-left: 32%;
    position: absolute;
    top: 77%;
  }
}

@media screen and (min-width: 1024px) {
  .visitTextBox {
    width: 50%;
    margin-left: 50px;
    position: relative;
  }
}

.boxWrapperVisit {
  width: 90%;
  height: 100%;
  justify-content: left;
  align-items: left;
  margin: auto;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .boxWrapperVisit {
    flex-direction: column;
    margin-bottom: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .boxWrapperVisit {
    flex-direction: row;
    align-items: start;
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 767px) {
  .boxWrapperVisit {
    flex-direction: column;
  }
}

.engageTextBox {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .engageTextBox {
    width: 60%;
    margin-left: 32%;
    position: absolute;
    top: 110%;
  }
}

@media screen and (min-width: 1024px) {
  .engageTextBox {
    width: 50%;
    margin-left: 50px;
    position: relative;
  }
}

.engageP {
  padding-bottom: 15px;
}

.boxWrapperEngage {
  width: 90%;
  height: 100%;
  justify-content: left;
  align-items: left;
  margin: auto auto 80px;
  display: flex;
  position: relative;
}

@media screen and (min-width: 768px) {
  .boxWrapperEngage {
    flex-direction: column;
    margin-bottom: 650px;
  }
}

@media screen and (min-width: 1024px) {
  .boxWrapperEngage {
    flex-direction: row;
    align-items: start;
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) {
  .boxWrapperEngage {
    flex-direction: column;
    margin-top: 50px;
  }
}

.greenText {
  color: #34e0a1;
  margin-top: 25px;
  margin-bottom: 0;
  font-family: Poppins, sans-serif;
  font-size: 3rem;
}

@media screen and (max-width: 767px) {
  .greenText {
    font-size: 2rem;
  }
}

.greenTextSecond {
  color: #34e0a1;
  margin-top: 0;
  margin-bottom: 12px;
  font-family: Poppins, sans-serif;
  font-size: 3rem;
}

@media screen and (max-width: 767px) {
  .greenTextSecond {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .buttonPosition {
    justify-content: left;
    display: flex;
  }
}

.buttonAttend {
  width: 192px;
  height: 64px;
  border-radius: 36px;
  margin-top: 35px;
  font-size: 16px;
}

@media screen and (max-width: 1023px) {
  .buttonAttend {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1024px) {
  .buttonAttend {
    width: 209px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;
  }
}

.subtitleEngage {
  margin-top: 15px;
}

.contact {
  margin-bottom: 100px;
}

.contact a {
  color: #34e0a1;
  text-decoration: underline;
}

.contact a:hover {
  cursor: pointer;
}

.contactContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 767px) {
  .contactContainer p {
    width: 90%;
    margin-bottom: 20px;
  }
}

/*# sourceMappingURL=index.5bb04b61.css.map */
