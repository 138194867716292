// COMPANY

.company {
  margin-bottom: 80px;
}

// CONTAINER

.company-container {

  @include min($tablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// HEADING

.company-heading {
  margin-bottom: 30px;

  @include min($tablet) {
    max-width: 265px;
    margin-bottom: 0;
  }

  @include min($desktop) {
    max-width: 510px;
  }
}

// LOGOS

.company-logos {
  position: relative;

  width: 155px;
  height: 155px;
  margin-right: auto;
  margin-left: auto;

  @include min($tablet) {
    width: 192px;
    height: 192px;
    margin-right: 0;
    margin-left: 0;
  }

  @include min($desktop) {
    width: 308px;
    height: 308px;
  }

  @include min($wide) {
    margin-right: 100px;
  }
}

.company-logo, .header-slide {
  opacity: 0;
  visibility: hidden;

  transform: translate3d(0, 15px, 0);
  transition-property: opacity, transform, visibility;
  transition-duration: .25s, .25s, 0s;
  transition-timing-function: $ease, $ease, linear;
  transition-delay: 0s, 0s, .25s;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

  // active

  .company-logo.is-active, .header-slide.is-active {
    opacity: 1;
    visibility: visible;

    transform: translate3d(0, 0, 0);
    transition-delay: .25s;
  }

  // each

  @for $index from 1 through 29 {
    .company-logo-#{ $index } {
      background-image: url("../assets/images/company/#{ $index }.jpg");
    }
  }

  @for $index from 20 through 29 {
    .header-slide-#{ $index } {
      background-image: url("../assets/images/company/#{ $index }.jpg");
    }
  }
