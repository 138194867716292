// HEADINGS

.h1 {
  font-family: $poppins;
  font-weight: 500;
  font-size: 4.2rem;
  line-height: 1.1;

  @include min($tablet) {
    font-size: 4.9rem;
  }

  @include min($desktop) {
    font-size: 9.6rem;
  }
}

.h2 {
  font-family: $merriweather;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4;

  @include min($tablet) {
    font-size: 1.7rem;
  }

  @include min($desktop) {
    font-size: 1.8rem;
  }
}

.h3 {
  font-family: $poppins;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.4;

  @include min($tablet) {
    font-size: 1.7rem;
  }

  @include min($desktop) {
    font-size: 1.8rem;
  }
}

.h4 {
  font-family: $merriweather;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;

  @include min($tablet) {
    font-size: 1.3rem;
  }

  @include min($desktop) {
    font-size: 1.5rem;
  }
}

