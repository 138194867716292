// ABOUT

.about {
  margin-bottom: 80px;

  @include min($desktop) {
    margin-bottom: 245px;
  }
}

// CONTAINER

.about-container {

}

// HEADING

.about-heading {

}

// ROTATE

.about-rotate {
  position: relative;

  height: 46px;
  margin-bottom: 40px;

  @include min($tablet) {
    height: 54px;
  }

  @include min($desktop) {
    height: 106px;
    margin-bottom: 70px;
  }
}

.about-rotate-item {
  opacity: 0;

  transform: translate3d(0, 15px, 0);
  transition-property: opacity, transform;
  transition-duration: .25s;
  transition-timing-function: $ease;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

  // active

  .about-rotate-item.is-active {
    opacity: 1;

    transform: translate3d(0, 0, 0);
    transition-delay: .25s;
  }

.about-rotate-image {
  width: 226px;
  height: 42px;

  background-image: url("../assets/images/about/logo-small.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;

  @include min($tablet) {
    width: 326px;
    height: 61px;

    background-image: url("../assets/images/about/logo-medium.svg");
  }

  @include min($desktop) {
    width: 660px;
    height: 123px;

    background-image: url("../assets/images/about/logo-large.svg");
  }
}

.about-rotate-text {

}

// SUBHEAD

.about-subhead {

}

  // before

  .about-subhead::before {
    transition-delay: .5s;
  }
