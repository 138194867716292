.gridFather{
  width: 100%;
}
.casesGrid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 2rem;
    margin: 0px 20px 100px 20px;
    @include min($tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: 0px 40px 150px 40px;

    }
    @include min($desktop) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      margin: 0px 60px 200px 60px;
    }
}

.casesCard {
    position: relative;
      @include min($desktop) {
      }
      &:hover {
        cursor: pointer;
      }
}

.awardCard {
  position: absolute;
  top: 5%;
  right: 0%;
}

.gridImg {
  width: 100%;
  object-fit: fill;
}

.gridText{
  padding-top: 18px;
}