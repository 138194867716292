// CALLOUT

.callout {
  margin-bottom: 80px;

  @include min($desktop) {
    margin-bottom: 100px;
  }
}

// CONTAINER

.callout-container {

}

// HEADING

.callout-heading {

  @include min($tablet) {
    max-width: 520px;
  }

  @include min($desktop) {
    max-width: 1000px;
  }
}

// LETTERS

.callout-letters {
  margin-bottom: 30px;

  color: $green;

  @include min($tablet) {
    margin-bottom: 20px;
  }

  @include min($desktop) {
    margin-bottom: 40px;
  }
}

.callout-letter {
  opacity: 0;

  transition-property: opacity;
  transition-duration: .25s;
  transition-timing-function: $ease;

  display: inline-block;
}

  // inview

  .callout-container.is-inview .callout-letter {
    opacity: 1;
  }

// BUTTON

.callout-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 154px;
  height: 64px;

  border-radius: 36px;
  background-color: $white;

  font-family: $poppins;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: $black;

  cursor: pointer;
  @include min($tablet) {
    width: 166px;
    height: 66px;
    border-radius: 36px;
    font-size: 18px;

  }

}
  // hover

  @include min($desktop) {
    .callout-button {
      transition: opacity .25s $ease;
    }

    .callout-button:hover {
      opacity: .75;
    }
  }
