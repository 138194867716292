// FOOTER

.footer {

}

// CONTAINER

.footer-container {
  padding-top: 50px;
  padding-bottom: 45px;

  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: $green;

  @include min($tablet) {
    padding-bottom: 80px;
  }

  @include min($desktop) {
    display: flex;
    align-items: center;
  }
}

// LOGO

.footer-logo {
  width: 92px;
  height: 92px;
  margin-bottom: 20px;

  background-image: url("../assets/images/footer/logo-small.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  @include image-replace;

  @include min($tablet) {
    margin-bottom: 25px;
  }

  @include min($desktop) {
    margin-right: 15px;
    margin-bottom: 0;
  }
}

// GROUP

.footer-group {

}

// LEGAL

.footer-legal {
  max-width: 240px;
  margin-bottom: 10px;

  font-family: $poppins;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;

  @include min($tablet) {
    max-width: 350px;
  }

  @include min($desktop) {
    max-width: none;
  }
}

// LINKS

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include min($desktop) {
    flex-direction: row;
  }
}

.footer-link {
  margin-bottom: 5px;

  font-family: $poppins;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.5;
  text-decoration: underline;

  cursor: pointer;

  @include min($desktop) {
    margin-right: 15px;
  }
}

  // hover

  @include min($desktop) {
    .footer-link {
      transition: opacity .25s $ease;
    }

    .footer-link:hover {
      opacity: .75;
    }
  }

  // last

  .footer-link:last-child {
    margin-bottom: 0;

    @include min($desktop) {
      margin-right: 0;
    }
  }
