.visitAdweek{
    display: flex;
    justify-content: center;


}

.visitText{
    width: 100%;
    @include min($desktop) {
      width: 90%;
  }
    

}

.visitTitlesBox{

    @include min($tablet) {
        width: 50%;
      }
      .h1{
        font-size: 4.2rem;
        @include min($tablet) {
            font-size: 4.7rem;
          }
          @include min($desktop) {
            font-size: 6.2rem;
          }
          @include min(1200px) {
            font-size: 7.5rem;
          }
          @include min(1470px) {
            font-size: 9.2rem;
          }
      }
      .h2 {
        font-size: 2rem;
        width: 100%;
        @include min($tablet)
        {
            width: 150%;

        }
        @include max($tablet)
        {
            margin: 10px 0px;
            font-size: 1.5rem;


        }
      }
}

.visitTextBox{
    width: 100%;
    margin-top: 30px;
    p {
        margin-bottom: 15px;
    }
    @include min($tablet) {
        margin-left: 32%;
        position: absolute;
        top:77%;
        width: 60%;
        margin-top: 0px;


    }
      @include min($desktop) {
        margin-left: 50px;
        position: relative;
        width: 50%;

      }

}
.boxWrapperVisit{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    position: relative;
    @include min($tablet) {
        flex-direction: column;
        margin-bottom: 400px;

      }
      @include min($desktop) {
        flex-direction: row;
        align-items: start;
        margin-bottom: 250px;


      }
      @include max($tablet)
      {
          flex-direction: column;
      }

  }

 