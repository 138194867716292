// HEADER

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  height: 100vh;

  @include min($tablet) {
    margin-bottom: 245px;
  }

  @include min($desktop) {
    margin-bottom: 245px;
  }

  @include max($desktop) {
    @media screen and (orientation: landscape) {
      height: 900px;
    }
  }
}

.headerAdWeek {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  height: 100vh;

  span {
    color: $green;
  }

  @include min($tablet) {
    margin-bottom: 245px;
  }

  @include min($desktop) {
    margin-bottom: 245px;
  }

  @include max($desktop) {
    @media screen and (orientation: landscape) {
      height: 900px;
    }
  }
}

// VIDEO

.header-video {
  opacity: 0;

  transition: opacity 0.5s linear;

  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

// loaded

.header-video.is-loaded {
  opacity: 1;
}

// LOGO
.logoWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @include max($desktop) {
    @media screen and (orientation: landscape) {
      height: 900px;
    }
  }
}
.header-logo {
  position: relative;
  width: 182px;
  height: 123px;

  background-image: url("../assets/images/header/logo.gif");
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;

  @include image-replace;

  @include min($desktop) {
    width: 350px;
    height: 236px;
  }
}

// ARROW

.header-arrow {
  position: absolute;
  bottom: 20px;
  left: 50%;

  display: block;
  width: 34px;
  height: 34px;
  margin-left: -17px;

  background-image: url("../assets/images/header/arrow-small.svg");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  cursor: pointer;

  @include min($tablet) {
    bottom: 30px;

    width: 44px;
    height: 44px;
    margin-left: -22px;

    background-image: url("../assets/images/header/arrow-large.svg");
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  background-color: black;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.swiper-pagination {
  margin-bottom: 10px;
  .swiper-pagination-bullet {
    background-color: transparent;
    height: 16px;
    width: 16px;
    border: 1px solid $green;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: $green;
  }
}

.headerBox {
  max-width: 250px;

  a {
    margin-top: 30px;
  }
  p {
    margin: 25px 0px;
    width: 80%;
    font-family: $poppins;
  }

  @include min($tablet) {
    max-width: 320;
  }
  @include min($desktop) {
    max-width: 1000;
  }
}

.headerBoxAdweek {
  max-width: 250px;
  margin-top: 200px;

  h1{
    @include min($tablet) {
      font-size: 8.2rem;
      width: 100%;
    }
    @include min(910px) {
      font-size: 10rem;
      width: 100%;
    }
  }

  a {
    margin-top: 30px;
    }

  @include min($tablet) {
    max-width: 100%;
  }
  @include min($desktop) {
    max-width: 954px;
  }
}

.boxWrapper {
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  z-index: 1;
  position: absolute;
  left: 5%;
}

.boxWrapperAdWeek {
  width: 90%;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  p {
    margin: 25px 0px;
    width: 310px;
    font-family: $poppins;
    @include min($tablet) {
      width: 480px;
    }
    @include min($desktop) {
      width: 100%;
    }
    @include max(390px) {
      width: 100%;
    }
  }
}

.videoHeader {
  width: 90%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @include max($desktop) {
    flex-direction: column;
  }
  @include min($desktop) {
    height: 350px;
    justify-content: space-between;
  }
}
.videoHeaderText {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 45%;
  h1 {
    width: 60%;
    @include min($tablet) {
      width: 20%;
    }
    @include min(450px) {
      width: 35%;
    }
    @include min($desktop) {
      width: 70%;
    }
  }
  h2 {
    width: 80%;
    margin-top: 10px;
  }

  .h2Custom {
    margin: 40px 0 !important;
  }

  @include max($desktop) {
    width: 90%;
  }
  @include max($desktop) {
    @media screen and (orientation: landscape) {
     margin-top: 130px;
    }
  }
}

.videoHeaderVideo {
  width: 45%;

  iframe {
    width: 100%;
    height: 100%;
  }

  @include max($tablet) {
    margin-top: 0px;
  }

  @include max($desktop) {
    width: 90%;
    margin-top: 20px;
  }
}

.italic{
  font-style: italic;
  color: white;
}

.colorGreen {
  color: $green;
}

.wandererText{
  margin: 40px 0px;
  @include max($desktop) {
    margin: 25px 0px;
  }
}
.wandererBanner{
  position: absolute;
}
.background-gradient {
  position: absolute;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  height: 100%;
  left: 0px;
  top:0px;
  @include min($desktop) {
    background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  }
}