.contact{
    a {
        text-decoration: underline;
        color: $green;
        &:hover{
            cursor: pointer;
        }
    }
    margin-bottom: 100px;
}

.contactContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @include max($tablet)
    {     
        p{
            width: 90%;
            margin-bottom: 20px;
        }

    }
}