// ENTER

.enter {
  opacity: 0;

  transition-property: opacity, transform;
  transition-duration: .5s;
  transition-timing-function: $ease;
}

  // each

  .enter-up { transform: translate3d(0, 5vh, 0); }

  // inview

  .enter.is-inview,
  .is-inview .enter {
    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

// DELAYS

@for $index from 1 through 2 {
  .delay-#{ $index } {
    transition-delay: $index * .25s;
  }
}
