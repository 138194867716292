$tablet:  768px;
$desktop: 1024px;
$wide:    1280px;

$poppins:  "Poppins", sans-serif;
$merriweather: "Merriweather", serif;
$trip-sans: "Trip Sans", sans-serif;

$black: #000;
$green: #34E0A1;
$white: #FFF;

$ease:        cubic-bezier(0.44, 0.21, 0, 1);
$ease-in:     cubic-bezier(0.75, 0, 1, 1);
$ease-out:    cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
