// HEADER

.casesHeader {
  position: relative;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  @include min($tablet) {
    margin-bottom: 40px;
    height: 280px;
  }

  @include min($desktop) {
    margin-bottom: 40px;
    height: 400px;
  }
  

  span {
    color: $green
    ;
  }
}


.titleContainer{
  display: flex;
  justify-content: left;
  width: 90%;
}

.worksTitle{
  width: 90%;
  margin-top: 100px;

  @include min(550px) {
    width: 60%;

  }
  @include min($tablet) {
    width: 50%;
    margin-top: 150px;
  }

  @include min($desktop) {
    width: 80%;
    margin-top: 140px;

  }

  @include min(1400px) {
    width: 50%;
  }
}

