.engageTextBox{
    width: 100%;
    @include min($tablet) {
        margin-left: 32%;
        position: absolute;
        width: 60%;
        top:110%
    }
      @include min($desktop) {
        margin-left: 50px;
        position: relative;
        width: 50%;

      }
}

.engageP{
  padding-bottom: 15px;
}


.boxWrapperEngage{
    width: 90%;
    margin: auto;
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: left;
    position: relative;
    margin-bottom: 80px;
    @include min($tablet) {
        flex-direction: column;
        margin-bottom: 650px;

      }
      @include min($desktop) {
        flex-direction: row;
        align-items: start;
        margin-bottom: 200px;

      }
      @include max($tablet)
      {     
          margin-top: 50px;
          flex-direction: column;
      }
  }

  .greenText{
    color: $green;
    font-family: $poppins;
    font-size: 3rem;
    margin-bottom: 0px;
    margin-top: 25px;
    @include max($tablet)
    {     
        font-size: 2rem;

    }
  }

  .greenTextSecond{
    color: $green;
    font-family: $poppins;
    font-size: 3rem;
    margin-bottom: 12px;
    margin-top: 0px;
    @include max($tablet)
    {     
        font-size: 2rem;

    }
  }

 .buttonPosition{
    @include max($tablet)
    {     
        display: flex;
        justify-content: left;

    }

 }

 .buttonAttend{
  margin-top: 35px;
  @include max($desktop) {
    margin-top: 40px;
}

  font-size: 16px;
  width: 192px;
  height: 64px;
  border-radius: 36px;
  @include min($desktop) {
      font-size: 18px;
      width: 209px;
      height: 66px;
      border-radius: 36px;
  }
}
.subtitleEngage{
  margin-top: 15px;
}